<template>
  <v-container class="other_pages">
    <v-row
      justify="center"
      class="full_height"
    >
      <v-col
        sm="10"
        md="8"
        lg="6"
      >
        <h2 class="text-h4 my-4">
          Contact Us
        </h2>
        <validation-observer ref="observer">
          <v-form
            ref="form"
            :disabled="isSubmitting"
            lazy-validation
            @submit.prevent="onSubmit"
          >
            <v-card>
              <v-card-text>
                <p
                  v-if="hasSubmitted && !submissionError"
                  class="text-body-1"
                >
                  Thanks for your message, we will get back to you as soon as we
                  can.
                </p>
                <p
                  v-if="!hasSubmitted || submissionError"
                  class="text-body-1"
                >
                  Don't be a stranger, just say hello!
                  <v-icon
                    right
                    color="homebuttoncolour"
                    @click="randomAviator()"
                  >
                    mdi-hand-wave
                  </v-icon>
                </p>
                <v-row
                  class="mt-4"
                  v-if="!hasSubmitted && !submissionError"
                >
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-text-field
                        label="Your Name"
                        :placeholder="aviatorName"
                        persistent-placeholder
                        required
                        v-model="name"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-text-field
                        label="Your Email"
                        :placeholder="aviatorEmail"
                        persistent-placeholder
                        required
                        v-model="email"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row v-if="!hasSubmitted && !submissionError">
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-textarea
                        label="Your Message"
                        placeholder="Let us know what's on your mind..."
                        persistent-placeholder
                        auto-grow
                        required
                        persistent-hint
                        v-model="message"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <p
                  v-if="submissionError"
                  class="text-body-1 text-center red--text mt-2 mb-0"
                >
                  {{ submissionError }}
                </p>
              </v-card-text>
              <v-card-actions v-if="!hasSubmitted && !submissionError">
                <v-spacer />
                <v-btn
                  color="homebuttonbg"
                  type="submit"
                  class="px-3 homebuttoncolour--text"
                  :disabled="isSubmitting"
                >
                  <v-icon left>
                    mdi-send
                  </v-icon>
                  <span>Send Message</span>
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
    <section class="no-parallax shorter_content">
      <footer-bar />
    </section>
  </v-container>
</template>

<script>
import FooterBar from "../components/FooterBar.vue";

import {supabase} from "../supabase";
import {length, required} from "vee-validate/dist/rules";
import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from "vee-validate";

const famousAviators = [
  {name: "Amelia Earhart", email: "amelia.earhart@aviators.aero"},
  {name: "Howard Hughes", email: "howard.hughes@aviators.aero"},
  {name: "Orville Wright", email: "orville.wright@aviators.aero"},
  {name: "Wilbur Wright", email: "wilbur.wright@aviators.aero"},
  {name: "Amy Johnson", email: "amy.johnson@aviators.aero"},
  {name: "Charles Lindbergh", email: "charles.lindbergh@aviators.aero"},
  {name: "Bessie Coleman", email: "bessie.coleman@aviators.aero"},
  {name: "Anne Morrow Lindbergh", email: "anne.morrow.lindbergh@aviators.aero"},
  {name: "Frank Whittle", email: "frank.whittle@aviators.aero"},
  {name: "Jean Batten", email: "jean.batten@aviators.aero"}
];

export default {
  name: "Contact",

  created() {
    setInteractionMode("eager");

    extend("required", {
      ...required,
      message: "Required"
    });
    extend("length", {
      ...length,
      message: "Must be {length} characters"
    });

    this.randomAviator();
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    FooterBar
  },

  data() {
    return {
      isSubmitting: false,
      hasSubmitted: false,
      submissionError: false,
      aviatorName: "",
      aviatorEmail: "",
      name: "",
      email: "",
      message: ""
    };
  },

  methods: {
    randomAviator: function () {
      const aviator =
        famousAviators[Math.floor(Math.random() * famousAviators.length)];
      this.aviatorName = aviator.name;
      this.aviatorEmail = aviator.email;
    },
    onSubmit: function () {
      this.isSubmitting = true;
      this.$refs.observer
        .validate()
        .then(async isValid => {
          if (isValid) {
            try {
              await supabase.functions.invoke("contact-form", {
                body: {
                  name: this.name,
                  email: this.email,
                  message: this.message
                }
              });
              this.hasSubmitted = true;
              this.submissionError = false;
            } catch (e) {
              console.error(e);
              this.submissionError = e.message;
            }

            this.hasSubmitted = true;
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>
